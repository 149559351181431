import "photoswipe/style.css";
import "$styles/index.css";

// Import all JavaScript & CSS files from src/_components
import PhotoSwipeLightbox from "photoswipe/lightbox";
import components from "$components/**/*.{js,jsx,js.rb,css}";

document.addEventListener("DOMContentLoaded", () => {
  // Mobile menu
  const navigation = document.querySelector("#navigation");
  const openMenuButton = document.querySelector("#open-menu-button");
  const closeMenuButton = document.querySelector("#close-menu-button");

  openMenuButton.addEventListener("click", () => {
    navigation.classList.toggle("hidden");
    openMenuButton.classList.toggle("hidden");
    closeMenuButton.classList.toggle("hidden");
  });

  closeMenuButton.addEventListener("click", () => {
    navigation.classList.toggle("hidden");
    openMenuButton.classList.toggle("hidden");
    closeMenuButton.classList.toggle("hidden");
  });

  // Back top top button
  const backToTopButton = document.querySelector("#back-to-top");

  backToTopButton.addEventListener("click", () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      backToTopButton.classList.remove("hidden");
    } else {
      backToTopButton.classList.add("hidden");
    }
  });

  // Lightbox
  const lightbox = new PhotoSwipeLightbox({
    gallery: "#gallery",
    children: "a",
    pswpModule: () => import("photoswipe"),
  });

  lightbox.on("uiRegister", function () {
    lightbox.pswp.ui.registerElement({
      name: "custom-caption",
      order: 9,
      isButton: false,
      appendTo: "root",
      html: "Caption text",
      onInit: (el, pswp) => {
        lightbox.pswp.on("change", () => {
          const currSlideElement = lightbox.pswp.currSlide.data.element;
          let captionHTML = "";

          if (currSlideElement) {
            const hiddenCaption = currSlideElement.querySelector(
              ".hidden-caption-content"
            );

            if (hiddenCaption) {
              // get caption from element with class hidden-caption-content
              captionHTML = hiddenCaption.innerHTML;
            } else {
              // get caption from alt attribute
              captionHTML = currSlideElement
                .querySelector("img")
                .getAttribute("alt");
            }
          }
          el.innerHTML = captionHTML || "";
        });
      },
    });
  });

  lightbox.init();

  // Reviews
  const reviews = document.querySelector("#reviews");
  let rotationInterval = null;

  if (reviews !== null) {
    const items = reviews.children;
    let startIndex = 0;

    const rotateItems = () => {
      for (let i = 0; i < items.length; i++) {
        items[i].classList.add("hidden");
      }

      let index = startIndex % items.length;
      items[index].classList.remove("hidden");

      startIndex = (startIndex + 1) % items.length;
    };

    rotateItems();

    rotationInterval = setInterval(rotateItems, 3000);

    reviews.addEventListener("mouseenter", () => {
      clearInterval(rotationInterval);
    });

    reviews.addEventListener("mouseleave", () => {
      rotationInterval = setInterval(rotateItems, 3000);
    });
  }

  // Anchors
  scrollToAnchor = (anchorId) => {
    const targetElement = document.getElementById(anchorId);

    if (targetElement) {
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: targetPosition - 20,
        behavior: "smooth",
      });
    }
  };

  document.querySelectorAll("#navigation a").forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      if (window.location.pathname === "/") {
        e.preventDefault();
        const anchorId = this.getAttribute("href").substring(2);
        scrollToAnchor(anchorId);
      }
    });
  });
});
